<template>
  <div>
    <Dialog
      v-model:visible="visible"
      :header="modalHeader"
      modal
      :style="{ width: '50vw' }"
      :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
      v-if="appStore.userSettings.settingsConfig"
      ref="omuraSettingsModal"
    >
      <div class="grid" v-if="appStore.userSettings.settingsConfig">
        <div class="col-12 lg:col-4 md:col-4 sm:col-12">
          <Listbox
            v-model="appStore.userSettings.selectedTab"
            :options="appStore.userSettings.settingsConfig"
            optionLabel="label"
            class="w-full md:w-14rem hidden md:block"
          >
            <template #option="slotProps">
              <div class="flex align-items-center gap-1">
                <i :class="slotProps.option.icon"></i>
                <div>{{ slotProps.option.label }}</div>
              </div>
            </template>
          </Listbox>

          <Dropdown
            v-model="appStore.userSettings.selectedTab"
            :options="appStore.userSettings.settingsConfig"
            optionLabel="label"
            class="w-full block md:hidden"
          />
        </div>

        <div class="col-12 lg:col-8 md:col-8 sm:col-12">
          <component
            :is="NameEmailTab"
            v-if="appStore.userSettings.selectedTab.name == 'name_email'"
            :settings="settings"
          ></component>
          <component
            :is="PasswordTab"
            v-if="appStore.userSettings.selectedTab.name == 'password'"
            :settings="settings"
          ></component>
          <component
            :is="NotificationsTab"
            v-if="appStore.userSettings.selectedTab.name == 'notifications'"
            :settings="settings"
          ></component>
        </div>
      </div>

      <template #footer>
        <Button
          label="Chiudi"
          outlined
          severity="secondary"
          @click="visible = false"
        />
      </template>
    </Dialog>
  </div>
</template>

<script setup>
import { defineAsyncComponent, onMounted, ref, computed } from "vue";
import Dialog from "primevue/dialog";
import Listbox from "primevue/listbox";
import { useEventBus } from "@vueuse/core";
import { useAppStore } from "@/stores/app";
import Button from "primevue/button";
import NameEmailTab from "./NameEmailTab.vue";
import PasswordTab from "./PasswordTab.vue";
import NotificationsTab from "./NotificationsTab.vue";
import Dropdown from "primevue/dropdown";
import { useLoading } from "vue-loading-overlay";

const appStore = useAppStore();

const visible = ref(false);
const tabs = ref([]);
const selectedTab = ref(null);
const settings = ref(null);
const settingsConfig = ref(null);
const $loading = useLoading();
const omuraSettingsModal = ref();

const getData = () => {
  const loader = $loading.show();

  axios({
    baseURL: appStore.config.url,
    url: `/api/v1/modules/shared/settings`,
  })
    .then((res) => {
      appStore.userSettings.settingsConfig = res.data.settingsConfig;
      appStore.userSettings.settings = res.data.settings;
      appStore.userSettings.tabs = res.data.extra["tabs"];
      appStore.userSettings.selectedTab = appStore.userSettings.tabs[0];
    })
    .finally(() => loader.hide());
};

const modalHeader = computed(() => {
  return "Impostazioni - " + (selectedTab.value ? selectedTab.value.label : "");
});

const openSettingsModal = useEventBus("omurai::shared::open_settings_modal");
onMounted(() => {
  openSettingsModal.on(() => {
    visible.value = true;
    getData();
  });
});
</script>

<style lang="scss" scoped></style>
