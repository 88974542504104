<template>
  <div>
    <Dialog
      v-model:visible="visible"
      header="Collega il tuo account Telegram"
      modal
      :style="{ width: '50vw' }"
      :breakpoints="{ '1199px': '90vw', '575px': '100vw' }"
      ref="omuraTelegramSetupModal"
    >
      <div v-if="setup">
        <h5>Inquadra il QR Code con il tuo smartphone</h5>
        <qrcode-vue :value="setup.url" :size="size" level="H" render-as="svg" />
        <h6>({{ setup.token }})</h6>
        <h5>O clicca sul pulsante qui sotto</h5>
        <a :href="setup.url" target="_blank">Apri Telegram</a>
      </div>
    </Dialog>
  </div>
</template>

<script setup>
import QrcodeVue from "qrcode.vue";
import Dialog from "primevue/dialog";
import { ref, onMounted } from "vue";
import { useEventBus } from "@vueuse/core";
import { useLoading } from "vue-loading-overlay";
import { useAppStore } from "@/stores/app";

const appStore = useAppStore();

const visible = ref(false);
const openTelegramModal = useEventBus("omurai::shared::open_telegram_modal");
const $loading = useLoading();
const setup = ref();

onMounted(() => {
  openTelegramModal.on(() => {
    const loader = $loading.show();

    axios({
      baseURL: appStore.config.url,
      url: `/api/v1/modules/automation/telegram`,
    })
      .then((res) => {
        setup.value = res.data;
        visible.value = true;
      })
      .finally(() => loader.hide());
  });
});
</script>

<style lang="scss" scoped></style>
