<template>
  <div>
    <table class="w-full">
      <thead>
        <tr>
          <th>Evento</th>
          <th>Email</th>
          <th>
            Telegram
            <i
              class="ph ph-info"
              v-tooltip.left="'Devi collegare prima il tuo account Telegram'"
            ></i>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="notification in notifications" :key="notification.name">
          <td class="m-0 p-2 border-bottom-1">
            <h4 class="m-0" v-html="notification.label"></h4>
            <h5
              class="m-0 text-gray-500"
              v-html="notification.description"
            ></h5>
          </td>
          <td class="m-0 p-2 border-bottom-1 text-center">
            <ToggleSwitch
              v-model="
                appStore.userSettings.settings.notifications[
                  'projects.' + notification.name
                ].email
              "
            />
          </td>
          <td class="m-0 p-2 border-bottom-1 text-center">
            <ToggleSwitch
              v-model="
                appStore.userSettings.settings.notifications[
                  'projects.' + notification.name
                ].telegram
              "
              :disabled="!appStore.user.user_has_telegram_id"
            />
          </td>
        </tr>
      </tbody>
    </table>

    <Button
      label="Collega il tuo account Telegram"
      severity="info"
      @click="onTelegramSetupClick"
    />

    <Button label="Salva impostazioni notifiche" @click="onSaveSettings" />
  </div>

  <TelegramSetup />
</template>

<script setup>
import { useAppStore } from "@/stores/app";
import Button from "primevue/button";
import ToggleSwitch from "primevue/toggleswitch";
import { ref } from "vue";
import { useEventBus } from "@vueuse/core";
import TelegramSetup from "./TelegramSetup.vue";

const appStore = useAppStore();

const notifications = ref([
  {
    name: "project_created",
    label: "Nuovo progetto",
    description: "Quando viene creato un nuovo progetto",
  },
  {
    name: "user_assigned_to_task",
    label: "Assegnazione all'attività",
    description: "Quando vieni assegnato ad una attività",
  },
  {
    name: "remove_user_assignment_to_task",
    label: "Rimuovi assegnazione all'attività",
    description:
      "Quando vieni rimossa l'assegnazione di un utente ad una attività",
  },
]);

const onSaveSettings = () => {
  axios({
    baseURL: appStore.config.url,
    url: `/api/v1/modules/shared/settings/notifications`,
    method: "patch",
    data: {
      settings: appStore.userSettings.settings,
    },
  });
};

const openTelegramModal = useEventBus("omurai::shared::open_telegram_modal");
const onTelegramSetupClick = () => {
  openTelegramModal.emit();
};
</script>

<style lang="scss" scoped></style>
