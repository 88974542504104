<template>
  <div
    v-if="appStore"
    class="bg-white border-black-alpha-20 border-bottom-1 fixed left-0 p-2 top-0 w-full shadow-2"
  >
    <div class="align-items-center flex justify-content-between">
      <div class="align-items-center flex gap-1">
        <div class="flex gap-1">
          <Button
            class="text-2xl"
            icon="ph-duotone ph-list"
            rounded
            outlined
            aria-label="Menu"
            @click="visible = true"
            v-if="appStore.clientInfo && appStore.clientInfo.is_smartphone"
          />
          <Button
            class="text-2xl"
            icon="ph ph-gauge"
            rounded
            outlined
            aria-label="Home"
            @click="gotoModuleDashboard"
          />
        </div>
        <div id="navbarLeftSection"></div>
      </div>

      <div class="flex">
        <OmuraGlobalSearch />
        <Button
          class="ml-2 text-2xl"
          icon="ph ph-bell"
          rounded
          outlined
          aria-label="Notifiche"
          @click="onNotificationsToggle"
          disabled
        />
        <!-- <Popover ref="notificationsPopover">
          <div v-for="notification in notifications" class="flex gap-2 ">
            <div v-html="notification.updated_at"></div>
            <div v-html="notification.type"></div>
          </div>
        </Popover> -->

        <Button
          class="ml-2 text-2xl"
          icon="ph ph-user-circle"
          rounded
          outlined
          aria-label="Utente"
          @click="toggle"
        />
      </div>
    </div>

    <div
      id="navbarMobileSection"
      class="mt-2 overflow-scroll w-full"
      v-show="appStore.clientInfo && appStore.clientInfo.is_smartphone"
    ></div>
  </div>
  <Menu ref="userMenu" id="userMenu" :model="items" :popup="true" />

  <Drawer v-model:visible="visible" header="Menu">
    <div id="sidebarSection" class="border-1">
      <!-- <moduleSidebar v-if="appStore"></moduleSidebar> -->
    </div>
  </Drawer>

  <OmuraSettings />

  <Dialog
    v-model:visible="announcementVisible"
    modal
    :header="announcement.data.title"
    :style="{ width: '25rem' }"
    v-if="announcement"
  >
    <div v-html="nl2br(announcement.data.body)"></div>
    <template #footer>
      <div class="flex justify-end gap-2">
        <Button
          type="button"
          label="Ok, ho letto"
          @click="onDismisAnnouncementClick"
        ></Button>
      </div>
    </template>
  </Dialog>
</template>

<script setup>
import { ref, computed, defineAsyncComponent, onMounted } from "vue";
import Button from "primevue/button";
import Menu from "primevue/menu";
import { useAppStore } from "@/stores/app";
import OmuraGlobalSearch from "@SHARED/assets/js/components/Search/OmuraGlobalSearch.vue";
import Drawer from "primevue/drawer";
import OmuraSettings from "./Settings/OmuraSettings.vue";
import { useEventBus } from "@vueuse/core";
import Popover from "primevue/popover";
import nl2br from "nl2br";

import Dialog from "primevue/dialog";

const appStore = useAppStore();

const getData = () => {};

const userName = computed(() => appStore.user.name);
const openSettingsModal = useEventBus("omurai::shared::open_settings_modal");
const visible = ref(false);

const userMenu = ref();
const items = ref([
  {
    label: userName,
    items: [
      {
        label: "Impostazioni",
        icon: "ph-duotone ph-gear",
        command: () => {
          appStore.gotoUrl("/user/profile");
        },
      },
      /*{
        label: "Notifiche",
        icon: "ph-duotone ph-gear",
        command: () => {
          onOpenSettingsModal();
        },
      },*/
      {
        separator: true,
      },
      {
        label: "Esci",
        icon: "ph-duotone ph-sign-out",
      },
    ],
  },
]);

const toggle = (event) => {
  userMenu.value.toggle(event);
};

const gotoModuleDashboard = () => {
  window.location = `${appStore.config.url}/modules/${appStore.moduleConfig.dashboard_url}`;
};

const notificationsPopover = ref();
const notifications = ref();

const onNotificationsToggle = (event) => {
  notificationsPopover.value.toggle(event);
};

const onDismisAnnouncementClick = () => {
  axios({
    url: AppConfig.url + "/api/app/announcement",
    method: "POST",
    data: {
      id: announcement.value.id,
    },
  });
  announcementVisible.value = false;
};

const announcement = ref();
const announcementVisible = ref();

const onOpenSettingsModal = () => {
  openSettingsModal.emit();
};

onMounted(() => {
  console.log("omuranavbar mounted");
  if (appStore) {
    //const moduleSidebarPath = `@MODULES/${appStore.moduleConfig.name}/resources/assets/js/components/ModuleSidebar.vue`;
    const moduleSidebarPath = `./Modules/Projects/resources/assets/js/components/ModuleSidebar.vue`;
    console.log("load module sidebar", moduleSidebarPath);
    const moduleSidebar = defineAsyncComponent({
      loader: import(
        "@MODULES/Projects/resources/assets/js/components/ModuleSidebar.vue"
      ),
    });
  }

  axios({
    method: "GET",
    url: AppConfig.url + "/api/app/announcement",
  }).then((res) => {
    announcement.value = res.data.announcement;
    announcementVisible.value = true;
  });

  /* axios({
    method: "GET",
    url: AppConfig.url + "/api/notifications",
  }).then((res) => (notifications.value = res.data.notifications)); */
});
</script>

<style lang="scss" scoped></style>
