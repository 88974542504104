import { defineStore } from "pinia";
import { useToast } from "primevue/usetoast";
import { inject, ref } from "vue";

export const useAppStore = defineStore("appStore", () => {
  const config = ref();
  const DateTime = inject("DateTime");
  const user = ref();
  const toast = useToast();
  const formErrors = ref(null);
  const clientInfo = ref(null);
  const notifications = ref(null);
  const moduleConfig = ref(null);
  const isLoading = ref(false);
  const blocked = ref("");
  const selectedPopupMenuObject = ref(null);
  const userSettings = ref({
    settingsConfig: null,
    settings: null,
    tabs: null,
    selectedTab: null,
  });

  config.value = window.AppConfig;

  window.axios.interceptors.request.use(
    function (config) {
      formErrors.value = null;
      blocked.value = "";

      return config;
    },
    function (error) {
      isLoading.value = false;

      return Promise.reject(error);
    }
  );

  window.axios.interceptors.response.use(
    function (response) {
      if (response.data.message) {
        toast.add({
          severity: "info",
          summary: "Eureka!",
          detail: response.data.message,
          life: 3000,
        });
      }

      isLoading.value = false;

      return response;
    },
    function (error) {
      if (error.response.data.errors) {
        formErrors.value = error.response.data.errors;
      }

      if (error.response.data.message) {
        toast.add({
          severity: "error",
          summary: "Errore!",
          detail: error.response.data.message,
        });
      }

      isLoading.value = false;

      return Promise.reject(error);
    }
  );

  async function getModuleConfig() {
    await axios({
      baseURL: AppConfig.url,
      url: "/api/app/get-module-config/projects",
    })
      .then((res) => {
        //_module.value = res.data.data.config;
        clientInfo.value = res.data.data.client_info;
        moduleConfig.value = res.data.data.config;
      })
      .then(() => {
        if (clientInfo.value.is_smartphone) {
          document.body.className += "is-smartphone";
        } else {
          document.body.className += "is-desktop";
        }
      });

    /* _module.value = {
      info: AppConfig.module,
      config:
    } */
  }

  function formatDate(date, format) {
    if (date == null || date == "" || typeof date == "undefined") return;

    const newDate = DateTime.fromFormat(date, "yyyy-MM-dd")
      .toFormat(format ?? config.value.settings.date_format)
      .toLocaleString();
    return newDate;
  }

  function getLoggedUser() {
    axios({
      baseURL: config.value.url,
      method: "POST",
      url: "api/v1/modules/shared/users/get-logged-user",
    }).then((res) => (user.value = res.data.data.user));
  }

  function gotoUrl(url) {
    window.location = url;
  }

  function resetFormErrors() {
    formErrors.value = null;
  }

  function teleportDestination() {
    if (clientInfo.value.is_smartphone) {
      document.body.className += "is-smartphone";

      return "#navbarMobileSection";
    }

    return false;
  }

  getModuleConfig();
  getLoggedUser();

  return {
    config,
    toast,
    formErrors,
    clientInfo,
    user,
    notifications,
    moduleConfig,
    isLoading,
    blocked,
    selectedPopupMenuObject,
    userSettings,

    formatDate,
    user,
    gotoUrl,
    resetFormErrors,
    teleportDestination,
  };
});
